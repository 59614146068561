<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	RadioGroupItem,
	type RadioGroupItemProps,
	useForwardProps,
	RadioGroupIndicator,
} from 'radix-vue';

import { cn } from '@laam/lib/utils';
import { PhCheck } from '@phosphor-icons/vue';

const props = withDefaults(
	defineProps<
		RadioGroupItemProps & {
			class?: HTMLAttributes['class'];
			showCheck?: boolean;
			isSizeChart?: boolean;
		}
	>(),
	{
		class: '',
		showCheck: false,
		isSizeChart: false,
	},
);

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
	<RadioGroupItem
		v-bind="forwardedProps"
		:class="
			cn(
				'flex p-xl gap-md text-sm text-gray-500 font-semibold rounded-small data-[state=checked]:bg-gray-50 data-[state=checked]:text-gray-800',
				props.class,
			)
		"
	>
		<div
			class="grow lg:text-left"
			:class="{ 'lg:text-center': props.isSizeChart }"
		>
			<slot name="default" />
		</div>
		<RadioGroupIndicator
			v-if="showCheck"
			class="lg:flex hidden items-center justify-center"
		>
			<PhCheck size="20" />
		</RadioGroupIndicator>
	</RadioGroupItem>
</template>
